<template>
  <div class="text-edit">
    <!-- <vue-inline-text-editor
    :value.sync="val"
    :placeholder="placeholder"
    @blur="onBlur"
    @close="onClose"
    @change="onChange"
    @open="onOpen"
    @update="onUpdate">
  </vue-inline-text-editor> -->

  <template v-if="!edit">
  <span span class="display" @click="edit = true" @hover="showEdit != true">{{initVal ? initVal : placeholder}}</span> <v-icon color="white">mdi-pencil</v-icon>
  </template>

    <template v-if="kind === 'text'">
      <template v-if="edit == true">
        <v-text-field
        class="pt-0 mt-0 white--text"
        color="white"
        dark
        single-line
        :placeholder="placeholder"
        :value="val"
        @change="onUpdate"
        @blur.native="onUpdate"
        @keyup.enter.native="onUpdate"
        >
        <template v-slot:append-outer>
            <v-btn icon @click="onUpdate"><v-icon>mdi-check-bold</v-icon></v-btn>
            <v-btn icon @click="edit=false"><v-icon>mdi-cancel</v-icon></v-btn>
        </template>
      </v-text-field>
      </template>
    </template>




    <template v-else-i="kind == 'textarea'">

    </template>



  </div>
</template>

<script>

export default {
  name: 'inlineTextEditor',
  props:['initVal', 'initField','placeholder', 'kind'],
  components:{

  },
  watch:{
    initVal: function(val){
      process.env.NODE_ENV === "development" ? console.log( 'things changed', val ) : null;
      this.val = val
    }
  },
  computed: {

  },
  data: () => ({
    val: null,
    edit:false,
  }),
  methods:{
      onBlur() {
          // console.log(`text blur:`);
      },
      onClose() {
          // console.log(`text close:`);
      },
      onChange () {
          // console.log(`text change:`);
      },
      onOpen () {
          // console.log(`text open:`);
      },
      onUpdate(event) {
        let data = {text:event, field: this.initField}
        // process.env.NODE_ENV === "development" ? console.log( event, this.placeholder, event == this.placeholder ) : null;
        if(event !== this.placeholder){
          this.$emit('itChanged', data)
          this.val = event;
        }
        this.edit = false;
      }
  },
  updated(){
    process.env.NODE_ENV === "development" ? console.log( this.initVal ) : null;
    process.env.NODE_ENV === "development" ? console.log( !this.$isUndefined(this.initVal) ) : null;
    this.val = this.initVal;
  }
};




</script>


<style lang="scss">
.upload-image-comp{
  .edit-photo{
    margin-top: -40px;
  }
  img.preview{
    max-width: 320px;
  }
}
</style>
